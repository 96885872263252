<!--
 * @Description:
 * @Author: heqi
 * @Date: 2022-07-20 11:01:56
 * @LastEditTime: 2023-09-15 17:32:17
 * @LastEditors: tangjz
 * @FilePath: \mfzj\src\views\layout\work\okr\myokr\OkrHistory.vue
-->
<template>
  <div class="okr-history" v-loading="loading">
    <el-affix :offset="71">
      <MyokrTopVue :department="department" dateSelType="more" @confirmSelDate="confirmSelDate" ref="okrTopRef"></MyokrTopVue>
    </el-affix>

    <ul class="okr-history-list" v-if="data.length" v-infinite-scroll="loadMore" infinite-scroll-immediate="false" infinite-scroll-distance="0">
      <li class="okr-history-item" v-for="item in data" :key="item">
        <OkrTemplate :okrData="item" :date="selDate"  @deleteOkr="deleteOkr" @uploadOkr="uploadOkr" :showDepartment="true" :showRightBtn="false"></OkrTemplate>
      </li>
    </ul>
    <EmptyData v-else image="no-okr-target" description="暂无目标"></EmptyData>
  </div>
</template>

<script>
import { reactive, toRefs, computed } from '@vue/reactivity'
import { watch } from 'vue'
import MyokrTopVue from './component/MyokrTop.vue'
import { useRoute, onBeforeRouteLeave } from 'vue-router'
import OkrTemplate from './component/OkrTemplate.vue'
import { getOkrListHistory } from '@/apis/okr.js'
import { dialogMsg } from '@/utils/dialogMsg.js'
import { useStore } from 'vuex'

export default {
  components: {
    MyokrTopVue,
    OkrTemplate
  },
  setup () {
    const route = useRoute()
    const store = useStore()
    const state = reactive({
      department: computed(() => route.query.department),
      okr: computed(() => store.state.okr),
      okrType: 'pass',
      data: [],
      selDate: {
        year: '',
        month: ''
      },
      okrTopRef: null,
      params: {
        limit: 10,
        page: 1,
        cycle_id: undefined,
        dept_id: computed(() => route.query.depId),
        type: 1
      },
      noMore: computed(() => store.state.noMore),
      loading: false
    })
    watch(() => state.params.dept_id, () => {
      getOkrList()
    })

    // 日期选择切换
    const confirmSelDate = (data) => {
      state.params.cycle_id = Array.isArray(data.selID) ? data.selID : [data.selID]
      getOkrList()
    }

    const getOkrList = (date = state.okrTopRef.selDate, type = 'refresh') => {
      if (type !== 'refresh') {
        state.params.page += 1
        store.commit('setLoadMore', true)
        if (state.noMore) return store.commit('setLoadMore', false)
      } else {
        state.data = []
        state.params.page = 1
        // console.log(date)
      }
      state.loading = true
      getOkrListHistory(state.params).then(({ code, data, msg }) => {
        if (code === 0) {
          // console.log(data)
          state.params.page === 1 ? (state.data = data.data) : (state.data.push(...data.data))
          data.data.length < 10 && data.current_page > 1 ? store.commit('setNoMore', true) : store.commit('setNoMore', false)
        } else {
          dialogMsg('error', msg)
        }
      }).finally(() => {
        store.commit('setLoadMore', false)
        if (!state.data.length) store.commit('setNoMore', false)
        setTimeout(() => {
          state.loading = false
        }, 200)
      })
    }

    // 加载更多
    const loadMore = () => {
      getOkrList(state.okrTopRef.selDate, 'nofresh')
    }

    onBeforeRouteLeave(() => {
      store.commit('setNoMore', false)
    })

    const deleteOkr = (id) => {
      state.data = state.data.filter(i => i.id !== id)
    }

    const uploadOkr = () => {
      getOkrList()
    }
    return {
      ...toRefs(state),
      confirmSelDate,
      getOkrList,
      deleteOkr,
      loadMore,
      uploadOkr
    }
  }
}
</script>

<style lang="less" scoped>
// :deep(.el-affix--fixed) {
//   z-index: 2 !important;
// }
.okr-history {
  margin-left: 8px;
  position: relative;
}
:deep(.myokr-top) {
  &::before {
    content: '';
    position: absolute;
    top: -9px;
    left: -10px;
    display: inline-block;
    width: 110%;
    height: 8px;
    background: #f6f9fa;
    border: none;
    border: 0px;
    border-radius: 0;
  }
}
.okr-history-list {
  .okr-history-item {
    border-radius: 6px;
    margin-top: 8px;
    :deep(.okr-template-bottom) {
      border-top: 1px solid #F1F1F1 !important;
    }
  }
}
:deep(.evalute-template) {
  // margin-top: 8px;
}

:deep(.el-empty) {
  width: auto !important;
  padding: 130px 0;
  margin-bottom: 60px;
  margin-top: 8px;
  border: 1px solid #F1F1F1;
  border-radius: 6px;
  .el-empty__description {
    margin-top: 0;
    font-size: 14px;
    color: #999999;
  }
  .el-empty__bottom {
    margin-top: 0;
    height: 0;
  }
}
</style>
